import { useState } from "react";
import receiptKeys from "../assets/locales/en/receipt.json";
import { useDemoResult } from "../hooks/useDemoResult";
import {
  ContentField,
  GroupField,
  HeaderField,
  ReceiptInferenceResult,
} from "../types/receiptOacResponse";
import { classnames } from "../utils/classnames";

export type Line = HeaderField | ContentField;
export type Group = GroupField;

interface Keys {
  [key: string]: string;
}

interface ParsingResultTableProps {
  disabled?: boolean;
}

export function ParsingResultTable({
  disabled = false,
}: ParsingResultTableProps): JSX.Element {
  const { result, hover, select } = useDemoResult();
  const [isRowClicked, setIsRowClicked] = useState(false);

  const lines = (result as ReceiptInferenceResult).filter(
    field => field.type !== "group",
  ) as Line[];

  const lineKeys = Array.from(new Set(lines.map(line => line.key)));
  const lineGroups = lineKeys.map(key =>
    lines.filter(line => line.key === key),
  );

  const groups = (result as ReceiptInferenceResult).filter(
    field => field.type === "group",
  ) as Group[];

  const contentGroups = groups.filter(
    group =>
      group.properties.filter(property => property.type === "content").length,
  );

  const groupKeys = Array.from(
    new Set(
      contentGroups.flatMap(group =>
        group.properties.map(property => property.key),
      ),
    ),
  ).sort((a, b) => {
    if (a.includes("name")) return -1;
    if (b.includes("name")) return 1;
    return 0;
  });

  let dummyId = -1;
  const dummyProperty = {
    key: "",
    value: "",
    type: "content",
    confidence: 0,
    boundingBoxes: [],
  };
  const sortedGroups = contentGroups.map(group => {
    const sortedProperties = groupKeys.map(key => {
      const matchedProperty = group.properties.find(
        property => property.key === key,
      );
      if (matchedProperty) return matchedProperty;
      // if (key.includes("quantity"))
      //   return { ...dummyProperty, id: dummyId--, key, value: "1" };
      // if (key.includes("price"))
      //   return { ...dummyProperty, id: dummyId--, key, value: "0" };
      return { ...dummyProperty, id: dummyId--, key, value: "" };
    });
    return { ...group, properties: sortedProperties };
  });

  // useEffect(() => {
  //   // 이미지뷰에서 박스가 클릭되었을때만 자동 스크롤
  //   if (isRowClicked) {
  //     setIsRowClicked(false);
  //     return;
  //   }

  //   if (select.selectedBoxIds.length) {
  //     const firstGroupId =
  //       lineGroups.find(lineGroup =>
  //         lineGroup.find(line =>
  //           select.selectedBoxIds.includes(String(line.id)),
  //         ),
  //       )?.[0].id ??
  //       contentGroups.find(group =>
  //         group.properties.find(property =>
  //           select.selectedBoxIds.includes(String(property.id)),
  //         ),
  //       )?.id;

  //     document
  //       .getElementById(`row-${firstGroupId}`)
  //       ?.scrollIntoView({ behavior: "smooth", block: "center" });
  //   }
  // }, [select.selectedBoxIds]);

  const isLineGroupHovered = (lineGroup: Line[]) => {
    for (let line of lineGroup) {
      if (hover.hoveredBoxIds.includes(String(line.id))) {
        return true;
      }
    }
    return false;
  };

  const isGrouptHovered = (group: Group) => {
    if (hover.hoveredBoxIds.includes(String(group.id))) {
      return true;
    }
    const relatedBoxIds = group.properties.map(property => String(property.id));
    for (let boxId of relatedBoxIds) {
      if (hover.hoveredBoxIds.includes(String(boxId))) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <table className="w-[calc(100%-1.5rem)] table-fixed">
        <tbody className="flex flex-col items-start justify-start rounded">
          {lineGroups.map(lineGroup => {
            const contentLines = lineGroup.filter(
              line => line.type === "content",
            );
            return contentLines[0] ? (
              <tr
                className={classnames({
                  "bg-indigo-400 bg-opacity-20 border-indigo-400 border-opacity-10":
                    !disabled && isLineGroupHovered(lineGroup),
                  "bg-white border-transparent":
                    disabled || !isLineGroupHovered(lineGroup),
                  "border text-md p-3 font-normal text-left text-black text-opacity-90 break-words w-full rounded mx-2 my-1":
                    true,
                })}
                id={`row-${lineGroup[0].id}`}
                key={`${lineGroup[0].key}`}
                onMouseEnter={() =>
                  hover.setHoveredBoxIds(lineGroup.map(line => String(line.id)))
                }
                onMouseLeave={() => hover.setHoveredBoxIds([])}
                onClick={() => {
                  setIsRowClicked(true);
                  select.setSelectedBoxIds([String(contentLines[0].id)]);
                }}
              >
                <td className="w-[12rem] opacity-50">
                  {(receiptKeys as Keys)[contentLines[0].key]}
                </td>
                <td className="w-[calc(100%-12rem)] opacity-90">
                  {contentLines.map(line => line.value).join(" ")}
                </td>
              </tr>
            ) : null;
          })}
        </tbody>
      </table>
      {contentGroups[0] ? (
        <table className="w-[calc(100%-1.5rem)] table-fixed">
          <thead className="bg-neutral-100">
            <tr>
              {groupKeys.map(key => {
                const display = (receiptKeys as Keys)[key.split("/")[1]];
                return (
                  <th
                    scope="col"
                    className={classnames({
                      "border-b border-black/5 px-4 py-2.5 font-normal text-black/90 text-md break-words":
                        true,
                      "w-[20rem] text-left": display?.includes("name"),
                      "text-right": !display?.includes("name"),
                    })}
                  >
                    {display}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="rounded">
            {sortedGroups.map(group => {
              return (
                <tr
                  className={classnames({
                    "bg-indigo-400 bg-opacity-20 border-indigo-400 border-opacity-10":
                      !disabled && isGrouptHovered(group),
                    "bg-white border-transparent":
                      disabled || !isGrouptHovered(group),
                    "border text-md break-words w-full rounded mx-2 my-1": true,
                  })}
                  id={`row-${group.id}`}
                  key={`${group.id}`}
                  onMouseEnter={() =>
                    hover.setHoveredBoxIds([
                      String(group.id),
                      ...group.properties.map(property => String(property.id)),
                    ])
                  }
                  onMouseLeave={() => hover.setHoveredBoxIds([])}
                  onClick={() => {
                    setIsRowClicked(true);
                    select.setSelectedBoxIds([
                      String(group.id),
                      ...group.properties.map(property => String(property.id)),
                    ]);
                  }}
                >
                  {group.properties
                    .filter(property => property.type === "content")
                    .map(property => (
                      <td
                        className={classnames({
                          "px-4 py-2.5 border-b border-black border-opacity-5 text-black text-opacity-90 text-md font-normal":
                            true,
                          "text-left": property.key
                            .split(".")
                            .includes("product_name"),
                          "text-right": !property.key
                            .split(".")
                            .includes("product_name"),
                        })}
                      >
                        {property.value}
                      </td>
                    ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </>
  );
}
