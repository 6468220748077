export interface FooterProps {
  messages: string[];
}

export function Footer({ messages }: FooterProps) {
  return (
    <div className="h-[6rem] py-4 bg-white flex flex-col justify-center items-start gap-0.5">
      {messages.map(message => (
        <div className="flex flex-row items-center justify-start gap-1">
          <div className="relative w-4 h-4">
            <div className="w-1 h-1 left-[6px] top-[6px] absolute bg-black bg-opacity-25 rounded-full" />
          </div>
          <div className="text-black text-opacity-50 text-sm font-normal font-['Ups sans'] leading-tight">
            {message}
          </div>
        </div>
      ))}
    </div>
  );
}
