import { useEffect, useState } from "react";
import { Spinner } from "../components/Animation/Spinner";
import { Blocker } from "../components/Backdrop/Blocker";
import { Button, ButtonStyle } from "../components/Button";
import { Dialog } from "../components/Dialog";
import { Footer } from "../components/Footer/Footer";
import { MenuItem } from "../components/Header/Header";
import { Icon, Icons } from "../components/Icon";
import { DemoResultProvider } from "../hooks/useDemoResult";
import { DemoDetailView } from "../organisms/DemoDetailView";
import { DemoImageView } from "../organisms/DemoImageView";
import {
  DemoSampleView,
  ocrSamples,
  ocrForLlmSamples,
} from "../organisms/DemoSampleView";
import { ImageOacJson } from "../types/demo";
import {
  ImageOcrOacJson,
  OcrOacJsonResponse,
  convertResponseToImageOcrOacJson,
} from "../types/ocrOacResponse";
import { ImageReceiptOacJson } from "../types/receiptOacResponse";
import {
  ImageOcrForLlmOacJson,
  OcrForLlmOacJsonResponse,
} from "../types/ocrForLlmOacResponse";
import { useLocation } from "react-router-dom";

export type InferenceMenuId = "ocr" | "ocrForLlm";
const inferenceMenuItems: MenuItem[] = [
  {
    id: "ocr",
    title: "Document OCR",
    description: "Read all texts in a document.",
  },
  {
    id: "ocrForLlm",
    title: "OCR for LLM",
    description: "",
  },
  // {
  //   id: "receipt",
  //   title: "Receipt Extractor",
  //   description:
  //     "Extract key information contained in the receipt as key-value pairs.",
  // },
];

const initialSamples: Record<InferenceMenuId, ImageOacJson> = {
  ocr: convertResponseToImageOcrOacJson(
    ocrSamples[0].file as OcrOacJsonResponse,
  ),
  ocrForLlm: ocrForLlmSamples[0].file as OcrForLlmOacJsonResponse,
};

const footerMessageItems = [
  "Your uploaded images remain confidential as we do not store them separately.",
  "Note that our API supports processing up to 30 pages for multipage PDF and TIFF files. However, you are able to view the extraction results only for the first page in the demo.",
  "The response results from the demo may vary from those provided by the API.",
];

function Home() {
  const location = useLocation();
  const [inferenceMenuId, setInferenceMenuId] = useState<InferenceMenuId>(
    () => {
      switch (location.pathname) {
        case "/":
        case "/ocr":
          return "ocr";
        case "/la":
          return "ocrForLlm";
        default:
          return "ocr";
      }
    },
  );

  // 데모화면에 파일 업로드 시 하나의 파일은 여러 이미지로 구성될 수 있음
  const [infering, setInfering] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const [files, setFiles] = useState<ImageOacJson[]>([
    initialSamples[inferenceMenuId],
  ]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<ImageOacJson>(
    initialSamples[inferenceMenuId],
  );
  const [page, setPage] = useState<number>(0);

  const [image, setImage] = useState<string>("");
  const [imageScale, setImageScale] = useState<number>(1.0);
  const [hoveredBoxIds, setHoveredBoxIds] = useState<string[]>([]);
  const [selectedBoxIds, setSelectedBoxIds] = useState<string[]>([]);

  useEffect(() => {
    setFiles([initialSamples[inferenceMenuId]]);
    setSelectedFile(initialSamples[inferenceMenuId]);
  }, [inferenceMenuId]);

  useEffect(() => {
    setSelectedFile(files[selectedFileIndex]);
    setPage(0);
  }, [files, selectedFileIndex]);

  useEffect(() => {
    if (!selectedFile) return;
    setImage(selectedFile.imageBase64);
  }, [selectedFile]); // lint가 제안하는대로 loadImage를 포함하면 함수호출->컴포넌트렌더->함수재정의 무한반복이 발생함

  const onAddData = async (
    fileResponsesPromise: Promise<Array<ImageOacJson>>,
  ) => {
    setInfering(true);
    try {
      const newFileResponses = (await fileResponsesPromise).filter(
        file => !!file,
      );
      if (newFileResponses.length === 0) return;
      setFiles(oldFileResponse => [
        ...newFileResponses.reverse(),
        ...oldFileResponse,
      ]);
    } catch (e) {
      console.error(e);
      setDialogVisible(true);
    } finally {
      setInfering(false);
    }
  };

  const result = () => {
    switch (inferenceMenuId) {
      case "ocr":
        return (
          ((selectedFile as ImageOcrOacJson)?.pages &&
            (selectedFile as ImageOcrOacJson)?.pages[0]) ||
          null
        );
      // case "receipt":
      //   return (selectedFile as ReceiptImageOacJson)?.fields || null;
      case "ocrForLlm":
        let result: any;
        const file = selectedFile as ImageOcrForLlmOacJson;
        Object.keys(file).forEach(key => {
          if (key !== "imageBase64" && key !== "numBilledPages") {
            const value = file[key];
            result = value;
          }
        });
        return result;
    }
  };

  return (
    <DemoResultProvider
      value={{
        result: result(),
        hover: {
          hoveredBoxIds,
          setHoveredBoxIds,
        },
        select: {
          selectedBoxIds,
          setSelectedBoxIds,
        },
      }}
    >
      <div className="flex flex-col w-full h-full overflow-auto bg-white">
        {/* <Header
          menuItems={inferenceMenuItems}
          selectedMenuId={inferenceMenuId}
          handleMenuItemSelect={menuId => setInferenceMenuId(menuId)}
        />
        <div className="h-4 border-t border-black/90" /> */}
        <section className="grid flex-1 grid-cols-2 gap-3">
          <div className="grid grid-cols-[5rem_calc(100%-5rem)] gap-2">
            <DemoSampleView
              inferenceMenuId={inferenceMenuId}
              onAddData={onAddData}
              onSampleSelect={setSelectedFile}
            />
            <DemoImageView
              file={selectedFile}
              page={page}
              pageSelectHandler={setPage}
              image={image}
              imageScale={imageScale}
              imageScaleChangeHandler={setImageScale}
              inferenceMenuId={inferenceMenuId}
              infering={infering}
            />
          </div>
          <div className="flex flex-row">
            <DemoDetailView
              infering={infering}
              inferenceMenuId={inferenceMenuId}
            />
          </div>
        </section>
        <Footer messages={footerMessageItems} />
      </div>
      {infering && (
        <Blocker>
          <div className="w-[12rem] h-[12rem]">
            <Spinner />
          </div>
        </Blocker>
      )}
      {dialogVisible && (
        <Dialog
          isOpen
          onClose={() => setDialogVisible(false)}
          shouldCloseOnExternalClick={true}
          size="xs"
        >
          <Dialog.Content>
            <div className="flex flex-col items-center text-sm text-gray-500 h-[11rem] justify-center">
              <Icon source={Icons.NoResult} />
            </div>
            <div className="flex flex-col items-center justify-center h-32 gap-4 px-6 py-2">
              <div className="text-neutral-900 text-xl font-semibold font-['Ups sans'] leading-[30px]">
                Try again with a suitable image that:
              </div>
              <ul className="font-normal leading-snug text-gray-500 list-disc list-inside text-md">
                <li>Includes readable text</li>
                <li>Is formatted as png, jpeg, bmp, pdf, or tiff</li>
                <li>Has a file size under 50MB</li>
              </ul>
            </div>
          </Dialog.Content>
          <Dialog.Footer>
            <Button
              text="OK"
              style={ButtonStyle.BasicDark}
              onClick={() => setDialogVisible(false)}
            />
          </Dialog.Footer>
        </Dialog>
      )}
    </DemoResultProvider>
  );
}

export default Home;
