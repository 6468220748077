import { useMemo, useState } from "react";
// ocr
import billOfLading from "../assets/samples/ocr/bill_of_lading.json";
import businessCard from "../assets/samples/ocr/business_card.json";
import businessRegistration from "../assets/samples/ocr/business_registration.json";
import outpatientConfirmation from "../assets/samples/ocr/outpatient_confirmation.json";
import privacyPolicyConsent from "../assets/samples/ocr/privacy_policy_consent.json";
import productManual from "../assets/samples/ocr/product_manual.json";

// ocrforllm
import paper from "../assets/samples/ocrforllm/paper.json";
import commercialInvoice from "../assets/samples/ocrforllm/commercial_invoice.json";
import productUserManual from "../assets/samples/ocrforllm/product_user_manual.json";
import reportWithMultiStage from "../assets/samples/ocrforllm/report_with_multi_stage.json";
import reportWithIndex from "../assets/samples/ocrforllm/report_with_index.json";
import productUserManualWithTable from "../assets/samples/ocrforllm/product_user_manual_with_table.json";
import reportWithMainTitle from "../assets/samples/ocrforllm/report_with_main_title.json";

import { TooltipWrapper } from "../components/Tooltip/Tooltip";
import { useImageUploader } from "../hooks/useImageUploader";
import { DemoAddDataButton } from "../molecules/DemoAddDataButton";
import { InferenceMenuId } from "../pages/home";
import { ImageOacJson, OacJsonResponse } from "../types/demo";
import {
  OcrOacJsonResponse,
  convertResponseToImageOcrOacJson,
} from "../types/ocrOacResponse";
import {
  ReceiptOacJsonResponse,
  convertResponseToReceiptImageOacJson,
} from "../types/receiptOacResponse";
import { classnames } from "../utils/classnames";
import {
  ImageOcrForLlmOacJson,
  OcrForLlmOacJsonResponse,
} from "../types/ocrForLlmOacResponse";

type SampleFile = OacJsonResponse;
interface Sample {
  id: string;
  title: string;
  thumbnailPath: string;
  file: SampleFile;
}

export const ocrSamples: Sample[] = [
  {
    id: "bill_of_lading",
    title: "Bill of Lading",
    thumbnailPath: "/samples/ocr/thumbnail/bill_of_lading.png",
    file: billOfLading,
  },
  {
    id: "product_manual",
    title: "Product manual",
    thumbnailPath: "/samples/ocr/thumbnail/product_manual.jpeg",
    file: productManual,
  },
  {
    id: "business_registration",
    title: "Business Registration",
    thumbnailPath: "/samples/ocr/thumbnail/business_registration.png",
    file: businessRegistration,
  },
  {
    id: "business_card",
    title: "Business card",
    thumbnailPath: "/samples/ocr/thumbnail/business_card.jpg",
    file: businessCard,
  },
  {
    id: "outpatient_confirmation",
    title: "Hospital confirmation",
    thumbnailPath: "/samples/ocr/thumbnail/outpatient_confirmation.png",
    file: outpatientConfirmation,
  },
  {
    id: "privacy_policy_consent",
    title: "Privacy consent form",
    thumbnailPath: "/samples/ocr/thumbnail/privacy_policy_consent.jpeg",
    file: privacyPolicyConsent,
  },
];

export const ocrForLlmSamples: Sample[] = [
  {
    id: "commercial_invoice",
    title: "Commercial invoice",
    thumbnailPath: "/samples/ocrforllm/thumbnail/commercial_invoice.png",
    file: commercialInvoice,
  },
  {
    id: "paper",
    title: "Paper",
    thumbnailPath: "/samples/ocrforllm/thumbnail/paper.png",
    file: paper,
  },
  {
    id: "product_user_manual",
    title: "Product user manual",
    thumbnailPath: "/samples/ocrforllm/thumbnail/product_user_manual.jpeg",
    file: productUserManual as OacJsonResponse,
  },
  {
    id: "report_with_multi_stage",
    title: "Report with multi-stage",
    thumbnailPath: "/samples/ocrforllm/thumbnail/report_with_multi_stage.png",
    file: reportWithMultiStage,
  },
  {
    id: "report_with_index",
    title: "Report with index",
    thumbnailPath: "/samples/ocrforllm/thumbnail/report_with_index.png",
    file: reportWithIndex,
  },
  {
    id: "product_user_manual_with_table",
    title: "Product user manual with table",
    thumbnailPath:
      "/samples/ocrforllm/thumbnail/product_user_manual_with_table.png",
    file: productUserManualWithTable,
  },
  {
    id: "report_with_main_title",
    title: "Report with main title",
    thumbnailPath: "/samples/ocrforllm/thumbnail/report_with_main_title.png",
    file: reportWithMainTitle,
  },
];

// export const receiptSamples: Sample[] = [
//   {
//     id: "long_receipt",
//     title: "Long receipt",
//     file: longReceipt as unknown as ReceiptOacJsonResponse,
//   },
//   {
//     id: "overseas_receipt",
//     title: "Purchase receipt",
//     file: overseasReceipt as unknown as ReceiptOacJsonResponse,
//   },
//   {
//     id: "rotated_receipt",
//     title: "Rotated receipt",
//     file: rotatedReceipt as unknown as ReceiptOacJsonResponse,
//   },
//   {
//     id: "tilted_receipt",
//     title: "Tilted receipt",
//     file: tiltedReceipt as unknown as ReceiptOacJsonResponse,
//   },
//   {
//     id: "wrinkled_receipt",
//     title: "Crumpled receipt",
//     file: wrinkledReceipt as unknown as ReceiptOacJsonResponse,
//   },
//   {
//     id: "multi-item_receipt",
//     title: "Multi-item receipt",
//     file: multiItemReceipt as unknown as ReceiptOacJsonResponse,
//   },
// ];

export interface DemoImageViewProps {
  onAddData: (files: Promise<Array<ImageOacJson>>) => void;
  onSampleSelect: (file: ImageOacJson) => void;
  inferenceMenuId?: InferenceMenuId;
}

export function DemoSampleView({
  inferenceMenuId = "ocr",
  onAddData,
  onSampleSelect,
}: DemoImageViewProps): JSX.Element {
  const samples = useMemo(() => {
    if (inferenceMenuId === "ocr") {
      return ocrSamples;
    } else if (inferenceMenuId === "ocrForLlm") {
      return ocrForLlmSamples;
    }
    return ocrSamples; // default로 ocr sample
  }, [inferenceMenuId]);
  const [selectedSampleId, setSelectedSampleId] = useState<string | null>(
    samples[0].id,
  );
  const { uploadOcrFiles, uploadReceiptFiles, uploadOcrForLlmFiles } =
    useImageUploader();

  const onUploadFiles = (files: File[]) => {
    setSelectedSampleId(null);
    if (!onAddData) {
      return () => {};
    }
    if (inferenceMenuId === "ocr") {
      onAddData(uploadOcrFiles(files));
    }
    if (inferenceMenuId === "ocrForLlm") {
      onAddData(uploadOcrForLlmFiles(files));
    }
  };

  const onSampleClick = (sample: Sample) => {
    setSelectedSampleId(sample.id);
    if (inferenceMenuId === "ocr") {
      onSampleSelect(
        convertResponseToImageOcrOacJson(sample.file as OcrOacJsonResponse),
      );
    }
    if (inferenceMenuId === "ocrForLlm") {
      onSampleSelect(sample.file as OcrForLlmOacJsonResponse);
    }
  };

  return (
    <div className="flex flex-col gap-1 w-[5rem]">
      <div className="w-[5.25rem] h-[5.25rem] bg-indigo-400/10 rounded-sm border-2 border-indigo-400 border-dashed justify-center items-center gap-2.5 inline-flex">
        <DemoAddDataButton onUploadData={onUploadFiles} />
      </div>
      {samples.map(sample => (
        <button
          onClick={() => onSampleClick(sample)}
          className={classnames({
            "w-[5.25rem] h-[5.25rem] bg-indigo-400/10 rounded-sm border-2 justify-center items-center text-center gap-2.5 inline-flex text-white":
              true,
            "border-indigo-400": selectedSampleId === sample.id,
            "border-transparent": selectedSampleId !== sample.id,
          })}
        >
          <TooltipWrapper
            text={sample.title}
            className="left-[5.5rem]"
            arrowVisible
          >
            <img
              src={sample.thumbnailPath}
              alt={sample.title}
              className="text-sm font-normal text-black"
            />
          </TooltipWrapper>
        </button>
      ))}
    </div>
  );
}
