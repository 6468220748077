import {
  ImageOcrForLlmOacJson,
  OcrForLlmOacJsonResponse,
} from "../types/ocrForLlmOacResponse";
import {
  ImageOcrOacJson,
  OcrOacJsonResponse,
  convertResponseToImageOcrOacJson,
} from "../types/ocrOacResponse";
import {
  ImageReceiptOacJson,
  ReceiptOacJsonResponse,
  convertResponseToReceiptImageOacJson,
} from "../types/receiptOacResponse";

export function useImageUploader() {
  const uploadOcrFiles = async (selectedFiles: File[]) => {
    // TODO: 서버 주소를 환경변수로 설정
    const url = `${process.env.REACT_APP_BACKEND_URL}/ocr`;
    if (!selectedFiles || selectedFiles.length === 0) {
      return [];
    }
    const uploads = selectedFiles.map(async file => {
      const fd = new FormData();
      fd.append("image", file as Blob);

      try {
        const response = await fetch(url, {
          method: "POST",
          body: fd,
        });
        const image = (await response.json()) as OcrOacJsonResponse;
        return image;
      } catch (err) {
        console.error("err:", err);
        throw err;
      }
    });

    const files = (await Promise.all(uploads))
      .filter(response => response !== null)
      .map(response => convertResponseToImageOcrOacJson(response!));
    return files as ImageOcrOacJson[];
  };

  const uploadReceiptFiles = async (selectedFiles: File[]) => {
    // TODO: 서버 주소를 환경변수로 설정
    const url = `${process.env.REACT_APP_BACKEND_URL}/extractor/receipt`;
    if (!selectedFiles || selectedFiles.length === 0) {
      return [];
    }
    const uploads = selectedFiles.map(async file => {
      const fd = new FormData();
      fd.append("image", file as Blob);

      try {
        const response = await fetch(url, {
          method: "POST",
          body: fd,
        });
        const image = (await response.json()) as ReceiptOacJsonResponse;
        return image;
      } catch (err) {
        console.error("err:", err);
        throw err;
      }
    });

    const files = (await Promise.all(uploads))
      .filter(response => response !== null)
      .map(response => convertResponseToReceiptImageOacJson(response!));
    return files as ImageReceiptOacJson[];
  };

  const uploadOcrForLlmFiles = async (selectedFiles: File[]) => {
    // TODO: 서버 주소를 환경변수로 설정
    const url = `${process.env.REACT_APP_BACKEND_URL}/layout/llm?schema=ufso`;
    if (!selectedFiles || selectedFiles.length === 0) {
      return [];
    }
    const uploads = selectedFiles.map(async file => {
      const fd = new FormData();
      fd.append("image", file as Blob);

      try {
        const response = await fetch(url, {
          method: "POST",
          body: fd,
        });
        const image = (await response.json()) as OcrForLlmOacJsonResponse;
        return image;
      } catch (err) {
        console.error("err:", err);
        throw err;
      }
    });

    const files = (await Promise.all(uploads)).filter(
      response => response !== null,
    );
    return files as ImageOcrForLlmOacJson[];
  };

  return {
    uploadOcrFiles: uploadOcrFiles,
    uploadReceiptFiles: uploadReceiptFiles,
    uploadOcrForLlmFiles: uploadOcrForLlmFiles,
  };
}
