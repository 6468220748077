import { z } from "zod";

export const ZodVertex = z.object({ x: z.number(), y: z.number() });
export type Vertex = z.infer<typeof ZodVertex>;

export const ZodBox = z.object({
  page: z.number(),
  vertices: z.array(ZodVertex),
});
export type Box = z.infer<typeof ZodBox>;

export const ZodProperty = z.object({
  boundingBoxes: z.array(ZodBox),
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  type: z.string(),
  value: z.string(),
});
export type Property = z.infer<typeof ZodProperty>;

export const ZodGroupField = z.object({
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  properties: z.array(ZodProperty),
  type: z.literal("group"),
  value: z.string(),
});
export type GroupField = z.infer<typeof ZodGroupField>;

export const ZodHeaderField = z.object({
  boundingBoxes: z.array(ZodBox),
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  type: z.literal("header"),
  value: z.string(),
});
export type HeaderField = z.infer<typeof ZodHeaderField>;

export const ZodContentField = z.object({
  boundingBoxes: z.array(ZodBox),
  confidence: z.number(),
  id: z.number(),
  key: z.string(),
  type: z.literal("content"),
  value: z.string(),
});
export type ContentField = z.infer<typeof ZodContentField>;

export const ZodReceiptField = z.union([
  ZodGroupField,
  ZodHeaderField,
  ZodContentField,
]);
export type ReceiptField = z.infer<typeof ZodReceiptField>;

export type ReceiptInferenceResult = ReceiptField[];

export const ZodReceiptOacJsonResponse = z.object({
  apiVersion: z.string(),
  confidence: z.number(),
  documentType: z.string(),
  fields: z.array(ZodReceiptField),
  imageBase64: z.string(),
  mimeType: z.string(),
  modelVersion: z.string(),
  stored: z.boolean(),
});
export type ReceiptOacJsonResponse = z.infer<typeof ZodReceiptOacJsonResponse>;

export const ZodReceiptImageOacJson = ZodReceiptOacJsonResponse;
export type ImageReceiptOacJson = z.infer<typeof ZodReceiptImageOacJson>;

export const convertResponseToReceiptImageOacJson = (
  response: ReceiptOacJsonResponse,
): ImageReceiptOacJson => {
  return {
    ...response,
  };
};
